import * as _ from 'lodash'
import { FrontTheme } from '../type/FrontTheme'
import { hexToRgb, typedKeys } from './filters'
import { ArtistInfoType } from '../type/ArtistInfoType'

export default function settingStyle(
    rootStyle: CSSStyleDeclaration,
    theme: FrontTheme
): void {
    typedKeys(theme).forEach((key) => {
        const replace = key.replace('Color', '')
        const prop = `--color-${_.kebabCase(replace)}`
        rootStyle.setProperty(prop, theme[key])

        if (theme[key]) {
            const propRgb = `--color-${_.kebabCase(replace)}-rgb`
            rootStyle.setProperty(propRgb, hexToRgb(theme[key]))
        }
    })
}

export function pluralize(word: string, amount?: number): string {
    if (amount !== undefined && amount === 1) {
        return word
    }
    const plural: { [key: string]: string } = {
        '(quiz)$': '$1zes',
        '^(ox)$': '$1en',
        '([m|l])ouse$': '$1ice',
        '(matr|vert|ind)ix|ex$': '$1ices',
        '(x|ch|ss|sh)$': '$1es',
        '([^aeiouy]|qu)y$': '$1ies',
        '(hive)$': '$1s',
        '(?:([^f])fe|([lr])f)$': '$1$2ves',
        '(shea|lea|loa|thie)f$': '$1ves',
        sis$: 'ses',
        '([ti])um$': '$1a',
        '(tomat|potat|ech|her|vet)o$': '$1oes',
        '(bu)s$': '$1ses',
        '(alias)$': '$1es',
        '(octop)us$': '$1i',
        '(ax|test)is$': '$1es',
        '(us)$': '$1es',
        '([^s]+)$': '$1s',
    }
    const irregular: { [key: string]: string } = {
        move: 'moves',
        foot: 'feet',
        goose: 'geese',
        sex: 'sexes',
        child: 'children',
        man: 'men',
        tooth: 'teeth',
        person: 'people',
    }
    const uncountable: string[] = [
        'sheep',
        'fish',
        'deer',
        'moose',
        'series',
        'species',
        'money',
        'rice',
        'information',
        'equipment',
        'bison',
        'cod',
        'offspring',
        'pike',
        'salmon',
        'shrimp',
        'swine',
        'trout',
        'aircraft',
        'hovercraft',
        'spacecraft',
        'sugar',
        'tuna',
        'you',
        'wood',
    ]
    // save some time in the case that singular and plural are the same
    if (uncountable.indexOf(word.toLowerCase()) >= 0) {
        return word
    }
    // check for irregular forms
    for (const w in irregular) {
        if (irregular[w]) {
            const pattern = new RegExp(`${w}$`, 'i')
            const replace = irregular[w]
            if (pattern.test(word)) {
                return word.replace(pattern, replace)
            }
        }
    }
    // check for matches using regular expressions
    for (const reg in plural) {
        if (plural[reg]) {
            const pattern = new RegExp(reg, 'i')
            if (pattern.test(word)) {
                return word.replace(pattern, plural[reg])
            }
        }
    }
    return word
}

export function redirectLogin(redirectUrl?: string): void {
    window.location.href = `${
        process.env.REACT_APP_EC_API_URL
    }/mypage/login?redirect=${redirectUrl ?? window.location.href}`
}

export function checkArtistHiddenItems(
    artistInfo: ArtistInfoType | null,
    isLoadingArtistInfo = false
): boolean {
    return !!(
        isLoadingArtistInfo ||
        process.env.REACT_APP_ARTIST_IDS_HIDDEN_ITEMS?.split(',')?.find(
            (artistId) => Number(artistId) === artistInfo?.artistId
        )
    )
}

export function checkArtistHiddenShopItems(
    artistInfo: ArtistInfoType | null,
    isLoadingArtistInfo = false
): boolean {
    return !!(
        isLoadingArtistInfo ||
        process.env.REACT_APP_ARTIST_IDS_HIDDEN_SHOP_ITEMS?.split(',')?.find(
            (artistId) => Number(artistId) === artistInfo?.artistId
        )
    )
}

export function checkArtistHiddenNftLiveItems(
    artistInfo: ArtistInfoType | null,
    isLoadingArtistInfo = false
): boolean {
    return !!(
        isLoadingArtistInfo ||
        process.env.REACT_APP_ARTIST_IDS_HIDDEN_NFT_LIVE_ITEMS?.split(
            ','
        )?.find((artistId) => Number(artistId) === artistInfo?.artistId)
    )
}

export function checkArtistHiddenNowOnAir(
    artistInfo: ArtistInfoType | null,
    isLoadingArtistInfo = false
): boolean {
    return !!(
        isLoadingArtistInfo ||
        process.env.REACT_APP_ARTIST_IDS_HIDDEN_NOW_ON_AIR?.split(',')?.find(
            (artistId) => Number(artistId) === artistInfo?.artistId
        )
    )
}
